<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row class="no-gutters">
        <b-col>
          <h1 class="mr-sm-4 header-tablepage">RICH MENU DETAIL</h1>
        </b-col>
        <b-col class="text-right mt-2 mt-sm-0">
          <b-button @click.prevent="" class="btn-filter">
            Download Template
          </b-button>
        </b-col>
      </b-row>

      <b-tabs v-model="tabIndex">
        <b-tab title="Set up">
          <div class="bg-white">
            <div class="title-tabs">
              <b-row calss="">
                <b-col md="6" class="text-left d-flex">
                  <b-form-checkbox class="" id="general" name="general">
                  </b-form-checkbox>
                  <div>General Information</div></b-col
                >
                <b-col md="6" class="justify-content-end d-flex">
                  <div class="mr-1 text-link cursor-pointer">Save</div>
                  <div>|</div>
                  <div class="ml-1 text-link cursor-pointer">Cancel</div>
                </b-col>
              </b-row>
            </div>
            <div class="p-3">
              <b-row>
                <b-col md="6">
                  <InputText
                    v-model="form.name"
                    isRequired
                    textFloat="Name"
                    placeholder="Name"
                    type="text"
                  />
                </b-col>
                <b-col md="6">
                  <InputText
                    v-model="form.richMenuAlias"
                    textFloat="Alias"
                    placeholder="Alias"
                    type="text"
                  />
                </b-col>

                <b-col>
                  <b-form-checkbox
                    :id="`checkbox-default`"
                    :name="`checkbox-default`"
                    v-model="form.isDefault"
                    :value="1"
                    :unchecked-value="0"
                  >
                    Default
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </div>

            <Template ref="template" :data="form"></Template>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <FooterAction routePath="/setting/rich-menu" @submit="saveForm()" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText.vue";
import Template from "./components/Template.vue";

export default {
  components: {
    OtherLoading,
    InputText,
    Template,
  },

  data() {
    return {
      isLoading: true,
      form: {
        name: "",
        richMenuAlias: "",
        isDefault: false,
      },
      id: this.$route.params.id,
      tabIndex: 0,
      templateOptions: [{ text: "3*2", value: "3*2" }],
    };
  },
  validations() {
    return {
      form: {},
    };
  },
  async created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      this.isLoading = true;
      if (this.id > 0) {
        const res = await this.axios(`/lineRich/${this.id}`);
        if (res.data.result == 1) {
          this.form = res.data.detail;
        }
      }

      this.isLoading = false;
    },
    async saveForm() {
      this.$refs.template.$v.$touch();
      this.$v.form.$touch();
      if (this.$refs.template.$v.$error || this.$v.form.$error) return;
      // this.isLoading = true;
      // let payload = {
      //   ...this.$refs.template.form,
      //   ...this.form,
      //   chatBarText: this.$refs.template.form.chatBarText,
      // };
      // const res = await this.axios.post(`/lineRich/CreateRichMenu`, payload);
      // if (res.data.result == 1) {
      //   this.successAlert().then(() => this.$router.push("/setting/rich-menu"));
      // } else {
      //   this.errorAlert(res.data.message);
      // }

      // this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-link {
  text-decoration: underline;
}
</style>
